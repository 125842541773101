<template>
  <!-- Component Start -->
  <form
    @submit.prevent="submitForm"
    class="grid md:grid-cols-3 gap-4 sm:gap-8 w-full max-w-screen-lg mx-auto px-5 mb-20"
  >
    <div class="md:col-span-2">
      <h2 class="text-xl font-medium">Zadejte termín příjezdu a odjezdu</h2>

      <Alert v-if="alertMessage" :alert-message="alertMessage" class="mt-2" />
      <template v-else>
        <Alert
          v-if="parkingId == 2 || parkingId == 3"
          :alert-message="{
            type: 'info',
            msg: 'Naše parkoviště se nově nachází o 500m vedle na adrese Drnovská 507/73.',
          }"
          class="mt-2"
        />
      </template>

      <div class="mt-2 bg-white rounded-lg border border-gray-200 shadow-md">
        <div class="pt-5">
          <div class="sm:grid grid-cols-2 gap-4 px-5 sm:px-8 pb-8">
            <div class="mt-4 sm:mt-auto">
              <label class="text-sm font-semibold" for="date-start">Datum příjezdu *</label>
              <Datepicker
                id="date-start"
                class="mt-1 w-full text-sm date-input"
                v-model="dateStart"
                locale="cs"
                :format-locale="cs"
                format="dd.MM.yyyy"
                cancelText="zrušit"
                selectText="vybrat"
                autoApply
                :minDate="new Date()"
                :maxDate="new Date(2024, 12, 0)"
                :enableTimePicker="false"
                required
              />
            </div>
            <div class="mt-4 sm:mt-auto">
              <label class="text-sm font-semibold" for="date-end">Datum odjezdu *</label>
              <Datepicker
                id="date-end"
                class="mt-1 w-full text-sm date-input"
                v-model="dateEnd"
                locale="cs"
                :format-locale="cs"
                format="dd.MM.yyyy"
                cancelText="zrušit"
                selectText="vybrat"
                autoApply
                :minDate="new Date()"
                :maxDate="new Date(2024, 12, 0)"
                :enableTimePicker="false"
                required
              />
            </div>
            <div class="mt-4 sm:mt-auto">
              <label
                for="time-start"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >Čas příjezdu *</label
              >
              <select
                id="time-start"
                v-model="timeStart"
                required
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <template v-for="(value, index) in 24" :key="index">
                  <option :value="index + ':00'">
                    {{ index > 9 ? '' + index : '0' + index }}:00
                  </option>
                  <option :value="index + ':30'">
                    {{ index > 9 ? '' + index : '0' + index }}:30
                  </option>
                </template>
              </select>
            </div>
            <div class="mt-4 sm:mt-auto">
              <label
                for="time-end"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >Čas odjezdu *</label
              >
              <select
                id="time-end"
                v-model="timeEnd"
                required
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <template v-for="(value, index) in 24" :key="index">
                  <option :value="index + ':00'">
                    {{ index > 9 ? '' + index : '0' + index }}:00
                  </option>
                  <option :value="index + ':30'">
                    {{ index > 9 ? '' + index : '0' + index }}:30
                  </option>
                </template>
              </select>
            </div>
            <div class="col-span-2 mt-4 sm:mt-auto">
              <label
                for="persons"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Počet osob *</label
              >
              <select
                id="persons"
                v-model="persons"
                required
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option v-for="(value, index) in 8" :key="index" :value="value">
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2 class="text-xl font-medium">Informace</h2>
      <div class="bg-white rounded-lg border border-gray-200 shadow-md mt-2 py-6">
        <div class="px-5 sm:px-8 text-sm">
          Po kliknutí na následující tlačítko ověříme kapacitu parkoviště a transferu v zadaný
          termín.
        </div>

        <div class="flex flex-col px-8 pt-4">
          <button
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            type="submit"
            :disabled="isLoading"
          >
            <span v-if="!isLoading">Přejít k rezervaci</span>
            <span v-else
              ><svg
                role="status"
                class="inline h-4 w-4 animate-spin mr-2 text-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                /></svg
              >Ověřuji kapacitu...</span
            >
          </button>
        </div>
      </div>
    </div>
  </form>
  <!-- Component End  -->
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { cs } from 'date-fns/locale'
import Datepicker from 'vue3-date-time-picker'
import Alert from './Alert.vue'

export default {
  name: 'DateForm',
  components: {
    Datepicker,
    Alert,
  },
  props: {
    alertMessageTo: Object,
    parkingId: Number,
  },

  setup(props) {
    const alertMessage = ref(props.alertMessageTo)
    const dateStart = ref(null)
    const dateEnd = ref(null)
    const timeStart = ref(null)
    const timeEnd = ref(null)
    const persons = ref(null)
    const isLoading = ref(false)

    const submitForm = (props) => {
      isLoading.value = true
      axios({
        method: 'post',
        //url: 'http://parkovani.devs/api/api.php',
        url: 'https://parking-letiste.cz/api/api.php',
        data: {
          method: 'processDateForm',
          parkingId: props.parkingId,
          dateStart: dateStart.value,
          dateEnd: dateEnd.value,
          timeStart: timeStart.value,
          timeEnd: timeEnd.value,
          persons: persons.value,
        },
      }).then(function (response) {
        //errors
        if (response.data.alert) {
          alertMessage.value = response.data.alert
          isLoading.value = false
        } else {
          alertMessage.value = null
        }

        //success
        if (response.data.status) {
          //console.log(response.data)
          if (response.data.status === 'success') {
            window.eventBus.emit('successDateForm', {
              dateTimeStartVariants: response.data.dateTimeStartVariants,
              dateTimeEndVariant: response.data.dateTimeEndVariant,
              priceInfo: response.data.priceInfo,
              enabledVariants: response.data.enabledVariants,
              persons: response.data.persons,
            })
          }
        } else {
          isLoading.value = false
        }
      })
    }

    return {
      dateStart,
      dateEnd,
      timeStart,
      timeEnd,
      persons,
      submitForm,
      alertMessage,
      isLoading,
      cs,
    }
  },
}
</script>

<style>
.date-input .dp__input {
  background-color: var(--dp-background-color);
  border-radius: 0.5rem;
  font-family: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen, ubuntu, cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  border: 1px solid var(--dp-border-color);

  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem;
  color: var(--dp-text-color);
  box-sizing: border-box;
}

.date-input .dp__input_icon_pad {
  padding-left: 35px;
}

.dp__calendar_item .dp__cell_offset {
  color: #6c6c6c;
}

.dp__calendar_item .dp__cell_disabled {
  color: var(--dp-secondary-color);
  cursor: not-allowed;
}

.dp__calendar_item .dp__active_date {
  background: var(--dp-primary-color);
  color: var(--dp-primary-text-color);
}
</style>
