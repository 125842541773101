<template>
  <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 w-full mx-auto mb-6">
    <div
      className="md:col-span-3 flex sm:justify-between justify-center items-center bg-white border border-gray-200 shadow-sm p-5 header"
    >
      <div className="sm:block hidden">
        <h1 className="text-2xl font-bold">Rezervace parkoviště</h1>
        <p className="text-lg text-gray-600">a transferu na letiště</p>
      </div>
      <a v-if="parkingId == 1" href="http://parkoviste-letiste-ruzyne.cz/" target="_blank">
        <img src="img/logo-1-big.png" width="168" alt="" />
      </a>
      <a v-if="parkingId == 2" href="https://zaparkujlevne.cz/" target="_blank">
        <img src="img/logo-2-big.png" width="168" alt="" />
      </a>
      <a v-if="parkingId == 3" href="https://www.parkoviste-u-letiste.cz/" target="_blank">
        <img src="img/logo-3-big.png" width="168" alt="" />
      </a>
    </div>
  </div>
  <div>
    <date-form v-if="step === 1" :alert-message-to="alertMessage" :parkingId="parkingId" />
    <reservation-form
      v-if="step === 2"
      :date-time-start-variants="dateTimeStartVariants"
      :date-time-end-variant="dateTimeEndVariant"
      :price-info="priceInfo"
      :enabled-variants="enabledVariants"
      :persons="persons"
      :parkingId="parkingId"
    />
    <thank-you v-if="step === 3" :alert-message="alertMessage" :parkingId="parkingId" />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import ReservationForm from '../components/ReservationForm.vue'
import DateForm from '../components/DateForm.vue'
import ThankYou from '../components/ThankYou.vue'

export default {
  components: {
    ReservationForm,
    DateForm,
    ThankYou,
  },

  setup() {
    const route = useRoute()
    const step = ref(1)
    const parkingId = ref(1) //default ID = 1
    //Data for step 2 (reservation form)
    const dateTimeStartVariants = ref(null)
    const dateTimeEndVariant = ref(null)
    const priceInfo = ref(null)
    const enabledVariants = ref(null)
    const persons = ref(null)
    //Data for step 3 (thank you)
    const alertMessage = ref(null)

    //Reset to default
    const reset = () => {
      step.value = 1
      dateTimeStartVariants.value = ref(null)
      dateTimeEndVariant.value = ref(null)
      priceInfo.value = ref(null)
      enabledVariants.value = ref(null)
      persons.value = ref(null)
      alertMessage.value = ref(null)
    }

    const path = computed(() => route.path)

    const setBodyClass = () => {
      //set body classs
      let bodyEl = document.body
      if (parkingId.value === 1) bodyEl.classList.add('plr-parking')
      if (parkingId.value === 2) bodyEl.classList.add('zl-parking')
      if (parkingId.value === 3) bodyEl.classList.add('pul-parking')
    }

    //events
    onMounted(() => {
      //get referrer page
      parkingId.value = path.value.split('/')[1] ? Number(path.value.split('/')[1]) : 1
      setBodyClass()

      window.eventBus.on('successDateForm', (data) => {
        step.value = 2
        dateTimeStartVariants.value = data.dateTimeStartVariants
        dateTimeEndVariant.value = data.dateTimeEndVariant
        priceInfo.value = data.priceInfo
        enabledVariants.value = data.enabledVariants
        persons.value = data.persons
      })
      window.eventBus.on('successReservationForm', (data) => {
        step.value = 3
        alertMessage.value = data.alertMessage
      })
      window.eventBus.on('failureReservationForm', (data) => {
        step.value = 1
        alertMessage.value = data.alertMessage
      })
      window.eventBus.on('resetAll', () => {
        reset()
      })
    })

    return {
      step,
      dateTimeStartVariants,
      dateTimeEndVariant,
      priceInfo,
      enabledVariants,
      persons,
      alertMessage,
      parkingId,
    }
  },
}
</script>

<style>
/* .plr-parking,
.zl-parking,
.pul-parking {
  background-color: transparent;
}
.plr-parking h2 {
  color: white;
}
.plr-parking .header,
.zl-parking .header,
.pul-parking .header {
  display: none;
} */
</style>
