import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home DEF',
    redirect: '/1',
    component: Home,
  },
  {
    path: '/1',
    name: 'Home PLR',
    component: Home,
    beforeEnter: () => {
      window.location.href = 'https://parkovato.cz/rezervace/parkoviste-letiste-ruzyne'
    },
  },
  {
    path: '/2',
    name: 'Home ZL',
    component: Home,
    beforeEnter: () => {
      window.location.href = 'https://parkovato.cz/rezervace/zaparkuj-levne'
    },
  },
  {
    path: '/3',
    name: 'Home PUL',
    component: Home,
    beforeEnter: () => {
      window.location.href = 'https://parkovato.cz/rezervace/parkoviste-u-letiste'
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
